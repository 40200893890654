<template>
  <div class="designer--documentation">
    <div class="documentation--main">
      <div>
        <h1>Customize Model</h1>
      </div>
      <div class="docs--items">
        <div class="docs--item" id="sample">
          <h2>Sample panel</h2>
        </div>
        <div class="docs--details">
          <p>The sample panel provides available input types currently supported on the platform. These input samples are HTML 5 compatible and works seamlessly and customization.</p><br>
          <!-- <p><img src="../../assets/docs/images/samples.png" alt="DESIGNER" width="100%" height="auto" /></p><br> -->
          <p>The following input samples are currently available:</p>
          <ul class="docs--list">
            <li><b>Time</b>: Takes input manually or from drop-down modal, eg: 13:00.</li>
            <li><b>Text</b>: Takes input manually as single-line text input from users, eg: John Doe.</li>
            <li><b>Email</b>: Takes input manually as a fields that should contain an e-mail address from users, eg: johndoe@mail.com.</li>
            <li><b>Number</b>: Takes input manually as a number or unit incremented/ decremented from the input arrows, eg: 1, 2, 3.</li>
            <li><b>File</b>: Allows users to upload file from their device</li>
            <li><b>Date</b>: Takes input manually or from drop-down modal depending on browser support, eg: {{new Date().getFullYear()+"-"+(new Date().getMonth()+1)+"-"+new Date().getDate()}}.</li>
            <li><b>Tel</b>: Takes input manually as a phone number formatted text from users, eg: +234803*******.</li>
            <li><b>Select</b>: Single select sample that takes input from a preloaded drop-down.</li>
            <li> <s>Multi-Select: Not supported currently, will be added in the next update. </s></li>
            <li> <s>Image: Not supported currently, will be added in the next update. </s></li>
            <li> <s>Multiple Selection: Not supported currently, will be added in the next update. </s></li>
            <li> <s>Checkbox: Not supported currently, will be added in the next update. </s></li>
            <li> <s>Radio button: Not supported currently, will be added in the next update. </s></li>
          </ul>
          <p>To ensure data integrity, users should correctly label and name every inputs type to avoid duplicates. Naming and labeling inputs is discussed on the edit panel section.</p>
        </div><hr style="border-bottom: 1px solid rgba(209,213,219, 1);">
        <div class="docs--item" id="panel">
          <h2>Edit panels</h2>
        </div>
        <div class="docs--details">
          <p>To be customize a model, drag and drop an item from input samples onto the model panel. An edit panel will appear as soon as a sample is dropped.</p><br>
          <!-- <p><img src="../../assets/docs/images/panel.png" alt="DESIGNER" width="100%" height="auto" /></p><br> -->
          <p>The edit panel provides input options to fully customize a sample. Common customization otpions include:</p>
          <ul class="docs--list">
            <li><b>Label</b>: The name to display on the form label, eg: Phone.</li>
            <li><b>Name</b>: This is the name the input will be save with on the database, eg: phone_number.</li>
            <li><b>Placeholder</b>: This is the prompt that users will see when filling out the form, eg: Enter you phone number.</li>
            <li><b>Input Required</b>: Check this box if the input sample will be required when users fill out the form.</li>
            <li><b>Multiple Selection</b>: Check this box if the input sample is a multi-select drop-down.</li>
            <li><b>Options</b>: With the above select drop-down, users can add and/or remove drop-down options.</li>
          </ul>
          <p>When you are done customizing the panel, click on the <u>Save</u> button to persist your settings on the model.</p>
          <p>Add more input samples to the model panel and customize each one on the edit panel.</p>
        </div><hr style="border-bottom: 1px solid rgba(209,213,219, 1);">
        <div class="docs--item" id="model">
          <h2>Model panel</h2>
        </div>
        <div class="docs--details">
          <p>The model panel has the model title by default and should be edited in situ. The title has a 30 character limit. It also has model components if in edit model mode (check preview for more information on this).</p><br>
          <!-- <p><img src="../../assets/docs/images/model.png" alt="DESIGNER" width="100%" height="auto" /></p><br> -->
          <p>With the model panel, users have the freedom to re-arrange input samples. Users could also remove an existing sample. A sample becomes an active on the edit panel when a user clicks on it.</p>
          <p>Directly over the input sample on the model panel are three buttons marked <code><button>&#10006;</button></code> <code><button>&#9650;</button></code> <code><button>&#9660;</button></code></p>
          <p>These are used for removing, moving up or moving a sample input (more of this on moving and removing sample section).</p>
          <p>After customizing the model, click on the <u>Add Model</u> button to persist your settings and send model to the database.</p>
        </div><hr style="border-bottom: 1px solid rgba(209,213,219, 1);">
        <div class="docs--item" id="move">
          <h2>Move sample</h2>
        </div>
        <div class="docs--details">
          <p>After dropping a sample on the model panel, users have the fredom to move the samples up or down to the preferred position using the arrow buttons.</p><br>
          <!-- <p><img src="../../assets/docs/images/move.png" alt="DESIGNER" width="100%" height="auto" /></p><br> -->
          <p>The final position is the exact location a sample will assume at use time.
          <p>After customizing the model, click on the <u>Add Model</u> button to persist your settings and send model to the database.</p>
        </div><hr style="border-bottom: 1px solid rgba(209,213,219, 1);">
        <div class="docs--item" id="remove">
          <h2>Remove sample</h2>
        </div>
        <div class="docs--details">
          <p>The button marked <code><button>&#10006;</button></code> is used to remove a sample from the model panel.Users should note that this action can not be reversed once initiated.</p><br>
          <!-- <p><img src="../../assets/docs/images/remove.png" alt="DESIGNER" width="100%" height="auto" /></p><br> -->
          <p>Therefore, extra caution should be taken when clicking the button. In future release, a pop up modal will ask a user to confirm action.</p>
          <p>After customizing the model, click on the <u>Add Model</u> button to persist your settings and send model to the database.</p>
        </div><hr style="border-bottom: 1px solid rgba(209,213,219, 1);">
      </div>
    </div>
    <div class="documentation--nav">
			<!-- <aside class="aside--right">
				<div class="aside--container"> -->
					<div class="documentation--items">
            <div>
              <h1>On this page</h1>
            </div>
            <div class="documentation--links">
              <a class="documentation--link" href="#sample">Sample Panel</a>
              <a class="documentation--link" href="#panel">Edit Panel</a>
              <a class="documentation--link" href="#model">Model Panel</a>
              <a class="documentation--link" href="#move">Move Samplel</a>
              <a class="documentation--link" href="#remove">Remove Sample</a>
            </div>
          </div>
				<!-- </div>
			</aside> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "DesignerDocumentation",
};
</script>

<style scoped src="@/assets/docs/styles/documentation.css">
</style>