<template>
  <div class="model--documentation">
    <div class="documentation--main">
      <div>
        <h1>Active Models</h1>
      </div>
      <div class="docs--items">
        <div class="docs--item" id="lists">
          <h2>Available models</h2>
        </div>
        <div class="docs--details">
          <p>This page displays lists of all available models for a particular user. This includes both models the user created and the one the user was added to.</p><br>
          <!-- <p><img src="../../assets/docs/images/models.png" alt="TEMPLATES" width="100%" height="auto" /></p><br> -->
          <p>Users can select and view desired model by click or tapping the it. This will take the user directly to the preview page. User has the option of searching models by name.</p>
          <p>Models are by default arranged from the oldest at the top and the newly added at the bottom.</p>
        </div><hr style="border-bottom: 1px solid rgba(209,213,219, 1);">
        <div class="docs--item" id="selection">
          <h2>Select model</h2>
        </div>
        <div class="docs--details">
          <p>This page displays lists of all available models for a particular user. This includes both models the user created and the one the user was added to.</p><br>
          <!-- <p><img src="../../assets/docs/images/models.png" alt="TEMPLATES" width="100%" height="auto" /></p><br> -->
          <p>Users can select and view desired model by click or tapping the it. This will take the user directly to the preview page. User has the option of searching models by name.</p>
          <p>Models are by default arranged from the oldest at the top and the newly added at the bottom.</p>
        </div><hr style="border-bottom: 1px solid rgba(209,213,219, 1);">
      </div>
    </div>
    <div class="documentation--nav">
			<!-- <aside class="aside--right">
				<div class="aside--container"> -->
					<div class="documentation--items">
            <div>
              <h1>On this page</h1>
            </div>
            <div class="documentation--links">
              <a class="documentation--link" href="#lists">List of models</a>
              <a class="documentation--link" href="#selection">Model Selection</a>
            </div>
          </div>
				<!-- </div>
			</aside> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "ModelDocumentation",
};
</script>

<style scoped src="@/assets/docs/styles/documentation.css">
</style>