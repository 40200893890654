<template>
  <div class="user--documentation">
    <div class="documentation--main">
      <div>
        <h1>Share Form</h1>
      </div>
      <div class="docs--items">
        <div class="docs--item" id="invite">
          <h2>Inviting users to a model</h2>
        </div>
        <div class="docs--details">
          <p>Users with algoforms account could be granted access to models by invitation using their account ID/ email. New users need an account before they could be granted access.</p><br>
          <!-- <p><img src="../../assets/docs/images/user.png" alt="USER" width="100%" height="auto" /></p><br> -->
          <p>To invite a user, type in the user account ID (email) on the user page and click the <code><button>Confirm</button></code> button or the <code><button>Cancel</button></code> button otherwise.</p>
          <p>An invite will be sent to the user. Access will be granted as soon as they confirm the invite.</p>
        </div><hr style="border-bottom: 1px solid rgba(209,213,219, 1);">
        <div class="docs--item" id="share">
          <h2>Inviting users to a model</h2>
        </div>
        <div class="docs--details">
          <p>Users with algoforms account could be granted access to models by invitation using their account ID/ email. New users need an account before they could be granted access.</p><br>
          <!-- <p><img src="../../assets/docs/images/user.png" alt="USER" width="100%" height="auto" /></p><br> -->
          <p>To invite a user, type in the user account ID (email) on the user page and click the <code><button>Confirm</button></code> button or the <code><button>Cancel</button></code> button otherwise.</p>
          <p>An invite will be sent to the user. Access will be granted as soon as they confirm the invite.</p>
        </div><hr style="border-bottom: 1px solid rgba(209,213,219, 1);">
      </div>
    </div>
    <div class="documentation--nav">
			<!-- <aside class="aside--right">
				<div class="aside--container"> -->
					<div class="documentation--items">
            <div>
              <h1>On this page</h1>
            </div>
            <div class="documentation--links">
              <a class="documentation--link" href="#invite">Invite Users</a>
              <a class="documentation--link" href="#share">Share Form</a>
            </div>
          </div>
				<!-- </div>
			</aside> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "UserDocumentation",
};
</script>

<style scoped src="@/assets/docs/styles/documentation.css">
</style>