<template>
  <div class="insight--documentation">
    <div class="documentation--main">
      <div>
        <h1>Get Insight</h1>
      </div>
      <div class="docs--items">
        <div class="docs--item" id="filter">
          <h2>Filter data</h2>
        </div>
        <div class="docs--details">
          <p>The insight page contains past records submitted by users. With the filter option, form owners can select record value(s) to be downloaded.</p><br>
          <!-- <p><img src="../../assets/docs/images/model.png" alt="INSIGHT" width="100%" height="auto" /></p><br> -->
          <p>It contains both the current form values and the deleted ones. Hence, users have the freedom to extract both live and stale datasets.</p>
          <p>Click the <code><button>Filter</button></code> at the top of the page to begin. A modal dialog will be displayed from where users can tick preferred checkboxes.</p>
        </div><hr style="border-bottom: 1px solid rgba(209,213,219, 1);">
        <div class="docs--item" id="visualize">
          <h2>Visualize data</h2>
        </div>
        <div class="docs--details">
          <p>The insight page contains two charts depicting virtualisation of survey dataset. Selecting a model from the model page will display both charts and metrics on data.</p>
          <p>These are merely a one stop overview, therefore, users are encouraged to export and explore data with other virtualisation tools to have a holistic insight.</p>
        </div><hr style="border-bottom: 1px solid rgba(209,213,219, 1);">
        <div class="docs--item" id="export">
          <h2>Export data</h2>
        </div>
        <div class="docs--details">
          <p>Once the preferred form inputs are ticked as mentioned in the filter section, proceed to export data by clicking the <code><button>Export Data</button></code> button.</p><br>
          <!-- <p><img src="../../assets/docs/images/model.png" alt="INSIGHT" width="100%" height="auto" /></p><br> -->
          <p>The filtered values will serve as the key to data export. The data is exported in <b>.csv</b> format for further processing and analysis.</p>
        </div><hr style="border-bottom: 1px solid rgba(209,213,219, 1);">
      </div>
    </div>
    <div class="documentation--nav">
			<!-- <aside class="aside--right">
				<div class="aside--container"> -->
					<div class="documentation--items">
            <div>
              <h1>On this page</h1>
            </div>
            <div class="documentation--links">
              <a class="documentation--link" href="#filter">Filter Data</a>
              <a class="documentation--link" href="#visualize">Visualize Data</a>
              <a class="documentation--link" href="#export">Export Data</a>
            </div>
          </div>
				<!-- </div>
			</aside> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "InsightDocumentation",
};
</script>

<style scoped src="@/assets/docs/styles/documentation.css">
</style>