<template>
  <div class="preview--documentation">
    <div class="documentation--main">
      <div>
        <h1>Preview Models</h1>
      </div>
      <div class="docs--items">
        <div class="docs--item" id="executing">
          <h2>Executing a model</h2>
        </div>
        <div class="docs--details">
          <p>On preview page, users can run selected item from the lists on the model page </p><br>
          <!-- <p><img src="../../assets/docs/images/preview.png" alt="PREVIEW" width="100%" height="auto" /></p><br> -->
          <p>It allows users to either manually enter or select inputs from modal pop-up or drop-down list. After filling out the form, click the <code><button>Submit</button></code> button.</p>
          <p>The input will be sent and saved to the database. Model owners can go into edit mode by clicking on the pencil icon at the top right corner of the form.</p>
        </div><hr style="border-bottom: 1px solid rgba(209,213,219, 1);">
        <div class="docs--item" id="editing">
          <h2>Editing a model</h2>
        </div>
        <div class="docs--details">
          <p>Editing an existing model involves selecting an item from the available lists on the models page. This opens up the model on the preview page. An edit icon will be displayed at the top right corner of the form, if the user is the owner/ createor of the model.</p><br>
          <!-- <p><img src="../../assets/docs/images/editing.png" alt="PREVIEW" width="100%" height="auto" /></p><br> -->
          <p>Clicking the edit button takes the user to the designer page. To transfer the ownership of a model, visit the settings page and make the user a co-owner.</p>
        </div><hr style="border-bottom: 1px solid rgba(209,213,219, 1);">
      </div>
    </div>
    <div class="documentation--nav">
			<!-- <aside class="aside--right">
				<div class="aside--container"> -->
					<div class="documentation--items">
            <div>
              <h1>On this page</h1>
            </div>
            <div class="documentation--links">
              <a class="documentation--link" href="#executing">Execute Model</a>
              <a class="documentation--link" href="#editing">Edit Model</a>
            </div>
          </div>
				<!-- </div>
			</aside> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "PreviewDocumentation",
};
</script>

<style scoped src="@/assets/docs/styles/documentation.css">
</style>