<template>
  <div class="setting--documentation">
    <div class="documentation--main">
      <div>
        <h1>Update Model Setting</h1>
      </div>
      <div class="docs--items">
        <div class="docs--item" id="search">
          <h2>Search users</h2>
        </div>
        <div class="docs--details">
          <p>Model owners can search for users that are already invited to a model by typing in their account ID (email address) in the input field. Users with matching names are displayed.</p><br>
          <!-- <p><img src="../../assets/docs/images/invite.png" alt="SETTINGS" width="100%" height="auto" /></p><br> -->
          <p>A selected user will have a colored background. Multiple users can selected at the same time.</p>
        </div><hr style="border-bottom: 1px solid rgba(209,213,219, 1);">
        <div class="docs--item" id="assign">
          <h2>Assign roles to users</h2>
        </div>
        <div class="docs--details">
          <p>After searching and selecting users, roles could be assign individually or collectively. Be well informed that the role overrides existing one.</p><br>
          <!-- <p><img src="../../assets/docs/images/role.png" alt="SETTINGS" width="100%" height="auto" /></p><br> -->
          <p>New role is effective on the user's next login or if they refresh their login token.</p>
        </div><hr style="border-bottom: 1px solid rgba(209,213,219, 1);">
      </div>
    </div>
    <div class="documentation--nav">
			<!-- <aside class="aside--right">
				<div class="aside--container"> -->
					<div class="documentation--items">
            <div>
              <h1>On this page</h1>
            </div>
            <div class="documentation--links">
              <a class="documentation--link" href="#search">Search User</a>
              <a class="documentation--link" href="#assign">Assign Role</a>
            </div>
          </div>
				<!-- </div>
			</aside> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "SettingsDocumentation",
};
</script>

<style scoped src="@/assets/docs/styles/documentation.css">
</style>