<template>
  <div class="account--documentation">
    <div class="documentation--main">
      <div>
        <h1>New Account</h1>
      </div>
      <!-- <div id="account" class="main--item"> -->
      <div class="docs--items">
        <div class="docs--item" id="register">
          <h2>Create a new account</h2>
        </div>
        <div class="docs--details">
          <p>New users need an Algoforms account in order to use the application.</p>
          Setting up an account could be started by clicking the <code><u>sign in</u></code> button on the menu bar. 
          <p>On the sign in page, under the Login button, click the the <code><u>Sign up</u></code> button to begin the registeration process.</p><br>
          <!-- <p><img src="../../assets/docs/images/register.png" alt="SIGN UP" width="80%" height="auto" /></p><br> -->
          <p>By providing a few credentials that include the following:</p>
          <ul class="docs--list">
            <li>Full name</li>
            <li>Phone number</li>
            <li>Email</li>
            <li>Password</li>
          </ul>
          <p>you will create a new account and a verification mail will be sent to the inbox of the email provided (Do check your spam if you do not see that in the primary inbox).</p>
        </div><hr style="border-bottom: 1px solid rgba(209,213,219, 1);">
        <div class="docs--item" id="login">
          <h2>Login to an existing account</h2>
        </div>
        <div class="docs--details">
          <p>Existing users can login with Algoforms account credentials used when the account was created.</p>
          <p>A login process could be initiated by clicking the <u>sign in</u> button on the menu bar.</p><br>
          <!-- <p><img src="../../assets/docs/images/login.png" alt="SIGN UP" width="100%" height="auto" /></p><br> -->
          <p>On the sign in page, provide a your account credentials ie. email and password</p>
          <p>Users that have forgot their password could recover their account by typing in their email and clicking <u>Forgot Password</u> button, right under the email input.</p><br>
          <!-- <p><img src="../../assets/docs/images/reset.png" alt="RESET" width="100%" height="auto" /></p><br> -->
          <p>A reset password form will be displayed whcih prompts users for password. Type in the email address attached to your account and a reset password mail will be sent 
          to your inbox (Do check your spam if you do not see that in the primary inbox).</p>
        </div><hr style="border-bottom: 1px solid rgba(209,213,219, 1);">
        <div class="docs--item" id="setting">
          <h2>Account settings</h2>
        </div>
        <div class="docs--details">
          <p>Following a successful login, users can update either their existing account ID (email) or periodically their password (<b>recommended</b>).</P>
          <p>On your dashboard, click the <u>account</u> button at the bottom of the page.</p><br>
          <!-- <p><img src="../../assets/docs/images/account.png" alt="SETTINGS" width="100%" height="auto" /></p><br> -->
          <p>Users will be prompted with two forms to choose from. For password update, enter the old password, a new password and a confirmation of the new password.</p>
          <p>Click the <u>Update Password</u> button to persist your input. And for email update, enter a new email and click the corresponding <u>Update Email</u> button.</p><br>
          <p><b>Caution:</b> <span  style="color: red;">Users will lose access to existing form invitations when they update their email.</span></p>
        </div><hr style="border-bottom: 1px solid rgba(209,213,219, 1);">
        <div class="docs--item" id="privacy">
          <h2>Account privacy and security</h2>
        </div>
        <div class="docs--details">
          <p>A multi-Factor Authentication (MFA) should be enabled (<b>recommended</b>) to limit access to unauthorised users.</P>
          <p>On your dashboard, click the <u>account</u> button at the bottom of the page.</p>
          <p><img src="" alt="SETTINGS" /></p>
          <p>Users will be prompted to enter a token whenever a sign in action is initiated.</p>
        </div><hr style="border-bottom: 1px solid rgba(209,213,219, 1);">
      </div>
      <!-- <Main></Main>
      <Footer></Footer> -->
      <!-- </div><hr style="border-bottom: 1px solid rgba(209,213,219, 1);"> -->
    </div>
    <div class="documentation--nav">
			<!-- <aside class="aside--right">
				<div class="aside--container"> -->
					<div class="documentation--items">
            <div>
              <h1>On this page</h1>
            </div>
            <div class="documentation--links">
              <!-- <router-link class="documentation--link" to="/documentation/account#register">New Account</router-link> -->
              <a class="documentation--link" href="#register">New Account</a>
              <a class="documentation--link" href="#login">Login and Password</a>
              <a class="documentation--link" href="#setting">Account Settings</a>
              <a class="documentation--link" href="#privacy">Privacy and Security</a>
            </div>
          </div>
				<!-- </div>
			</aside> -->
    </div>
  </div>
</template>

<script>
// import Main from "@/components/partials/Main.vue";
// import Footer from "@/components/partials/Footer.vue";
export default {
  name: "AccountDocumentation",
  components: {
    // Main,
    // Footer,
  },
};
</script>

<style scoped src="@/assets/docs/styles/documentation.css">
</style>